<template>
  <div class="consortium">
    <!-- <div class="top_Img">
      <img :src="pageImg" alt="">
      <div class="tips">{{pageTitle}}</div>
    </div> -->
    <!-- 数据展示 -->
    <div v-if="loading" class="spin-loading">
      <a-spin tip="Loading..." size="large">
        <div class="spin-content"></div>
      </a-spin>
    </div>
    <div v-else>
      <div class="message" v-if="listData.length != 0">
        <div class="item" v-for="(item,index) of listData" :key="index" @click="handleViewDetails(item)">
          <div class="mettingTitle">{{item.meeting_name}}</div>
          <div class="timeAndaddress">
            <p><span class="bg common">会议时间</span><span class="text common">{{item.meeting_start_time}}~{{item.meeting_end_time}}</span></p>
            <p><span class="bg common">会议地点</span><span class="text common">{{item.meeting_address}}</span></p>
            <p><span class="bg common">主办单位</span><span class="text common">{{item.organizer}}</span></p>
          </div>
          <div class="content" v-html="item.content">
            {{item.content}}
          </div>
          <div class="publishtime">
            <!--<span>{{moment(item.pubdate).format('YYYY-MM-DD')}}</span>-->
      <span @click="handleViewDetails(item)" style="cursor:pointer;">查看详情>></span>
          </div>
        </div>
      </div>
      <div v-else style="text-align:center;margin-top:80px;">
        <img src="../../assets/nodata.jpg" alt="">
        <div class="tips">暂无文章</div>
      </div>
      <!-- 分页 -->
      <div class="pagination" v-if="listData.length != 0">
        <a-pagination :default-current="1" :total="total" @change="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data(){
    return {
      moment,
      searchMessage:{
        page_index:1,
        per_page_count:10,
      },
      total:1, // 分页数据总数
      listData:[], // 数据
      pageImg:'',
      pageTitle:'',

      loading:true,
    }
  },
  methods:{
    // 查看详情
    handleViewDetails(data){
      this.$router.push({path:'messageDetails',query:{isTop:'Top',metting:'metting',detailID:data.id}});
    },
    // 查询数据接口
    getDataList(){
      let ID = this.$route.query.id;
      this.$post('/v1/news/column/' + ID,this.searchMessage).then(res=>{
        this.total = res.all_news_count;
        res.data.forEach(item=>{
          if(item.content){
            item.content = item.content.replace(/<\/?(img)[^>]*>/gi, '').replace(/<\/?(iframe)[^>]*>/gi,'').replace(/<\/?(video)[^>]*>/gi,'');
          }
        })
        this.listData = res.data;
        this.pageImg = res.url;
        this.pageTitle = res.title;
        this.$root.$emit('changeImg',ID);
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
    // 页码查询
    changePage(page,pageSize){
      this.searchMessage.page_index = page;
      window.scrollTo(0,0)
      this.getDataList();
    }
  },
  mounted(){
    this.getDataList();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .ant-pagination-item-active a{color: #fff;}
  .consortium{
    .message{padding:12px;margin-top:20px;max-width:1400px;margin:0 auto;
      .item{border-bottom:1px solid #ececec;cursor: pointer;
        div{margin: 15px 0;}
        .mettingTitle{font-size: 20px;font-weight: bold;color: #333;}
        .timeAndaddress{display:flex;flex-wrap:wrap;
          .bg{background: #0080ff;border-top-left-radius: 4px;border-bottom-left-radius: 4px;color: #fff;border: 1px solid #0080ff;}
          .common{display: inline-block;padding: 4px 6px;font-size: 14px;margin-bottom: 8px;}
          .text{color: #333;border: 1px solid #0080ff;border-top-right-radius: 4px;border-bottom-right-radius: 4px;margin-right: 15px;}
        }
        .content{font-size: 12px;color: #333;word-break: break-all;margin-top: 15px;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;max-height: 50px !important;overflow: hidden;}
        .publishtime{font-size: 12px;color: #333;display: flex;justify-content: space-between;}
      }
    }
    .message{
      .item:hover{.mettingTitle{color: #ffc800;}}
    }
    .pagination{max-width: 1400px;margin: 0 auto;display: flex;justify-content: flex-end;}
  }
</style>
